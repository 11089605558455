const DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
// Import containers
import { DefaultLayoutComponent } from './containers';
import { FilterMenuPipe } from './containers/default-layout/filter-menu.pipe';
const APP_CONTAINERS = [DefaultLayoutComponent, FilterMenuPipe];
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
registerLocaleData(localePl, 'pl');
const ɵ0 = () => 'scp';
export class AppModule {
}
export { ɵ0 };
